<template>
  <div>
    <div class="top-padding">
      <div class="pay-now-page">
        <b-container>
          <div class="pay-now-page-wrap">
            <div class="thanks-order-block">
              <div class="thanks-order-info">
                <h1 class="top-heading">{{ $t("cancel_order") }}</h1>
                <p class="para-h">
                  {{ $t("order_cancel") }}
                  <b-link
                    v-if="typeof user.email == 'string'"
                    href="#"
                    class="span-txt"
                    >{{ user.email }}</b-link
                  >
                </p>
              </div>

              <div class="thanks-order-button">
                <b-link to="/" class="popup-link bg-primary mb-2">
                  <span class="mr-2"
                    ><font-awesome-icon icon="chevron-circle-left"
                  /></span>
                  {{ $t("continue_shopping") }}
                </b-link>
                <b-link to="/shopping-cart/" class="popup-link bg-success mb-2">
                  {{ $t("to_shopping_cart") }}
                  <span class="ml-2"
                    ><font-awesome-icon icon="chevron-circle-right"
                  /></span>
                </b-link>
              </div>
            </div>
            <div class="info-customer-block">
              <cmsBlockSimple
                v-if="typeof success_page == 'object'"
                :identifier="success_page.identifier"
              />
            </div>
          </div>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
import { Logger } from "@storefront/core/lib/logger";
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";

export default {
  name: "PaymentCancel",
  components: {
    cmsBlockSimple,
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("payment_cancel") },
        { name: "keywords", content: this.$t("payment_cancel") },
        { name: "description", content: this.$t("payment_cancel") },
      ],
      title: this.$t("payment_cancel"),
    };
  },
  computed: {
    transactionId() {
      if (typeof this.$store.state.route.query.transactionid != "undefined") {
        return this.$store.state.route.query.transactionid;
      }
      return null;
    },

    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    success_page() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "success_page"
      );
    },
  },
  async mounted() {
    const bcrumb = { current: "success", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    Logger.debug("router", "mounted", this.transactionId)();
    this.$store.dispatch("cart/loadCart");
  },

  data: () => ({ email: "" }),
};
</script>
